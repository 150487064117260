import { useContext, useEffect } from 'react';
import { Skeleton, Stack } from '@mui/material';
import { Control, FieldErrors } from 'react-hook-form';
import { LocationInfoFormData, WIDTH_DESKTOP, WIDTH_MOBILE } from '../models';
import { LargerThanBreakpoint } from '../../../../util/viewportUtils';
import ControlDropdownInput from '../../../../components/common-materialui/form/ControlDropdownInput';
import ControlTextInput from '../../../../components/common-materialui/form/ControlTextInput';
import { EmptyingLocationContext } from '../provider/EmptyingLocationContextProvider';
import { UiTexts } from '../../../../model';

interface Props {
  control: Control<LocationInfoFormData, any>;
  errors: FieldErrors<LocationInfoFormData>;
  setAlertErrorMsg: (msg: keyof UiTexts) => void;
}

const BuildingInfoSection = ({ control, errors, setAlertErrorMsg }: Props): JSX.Element => {

  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;

  const {
    alertErrorMsg,
    areasLoading,
    areaOptions,
    conurbationsLoading,
    conurbationOptions,
    buildingUsageTypesLoading,
    buildingUsageTypeOptions,
    buildingTypesLoading,
    buildingTypeOptions,
  } = useContext(EmptyingLocationContext);

  // Set alert error message from context to upper level
  useEffect(() => {
    if (alertErrorMsg) {
      setAlertErrorMsg(alertErrorMsg);
    }
  }, [alertErrorMsg, setAlertErrorMsg]);

  return (
    <Stack direction='column' width={width}>
      <Stack direction='column' spacing={1.5} my={1}>
        {areasLoading ? (
          <Skeleton variant='rectangular' height={56} width={'100%'}></Skeleton>
        ) : (
          <ControlDropdownInput
            control={control}
            error={errors.area}
            label={'add-emptying-location-building-info-area'}
            validations={['required']}
            dropdownItems={areaOptions}
            name={'area'}
          />
        )}
        {conurbationsLoading ? (
          <Skeleton variant='rectangular' height={56} width={'100%'}></Skeleton>
        ) : (
          <ControlDropdownInput
            control={control}
            error={errors.conurbation}
            label={'add-emptying-location-building-info-conurbation'}
            validations={['required']}
            dropdownItems={conurbationOptions}
            name={'conurbation'}
          />
        )}
      </Stack>

      <ControlTextInput
        control={control}
        error={errors.permanentBuildingId}
        label={'add-emptying-location-building-info-permanent-id'}
        validations={['positive-integer']}
        name={'permanentBuildingId'}
      />

      <Stack direction='column' spacing={1.5} my={1}>
        {buildingUsageTypesLoading ? (
          <Skeleton variant='rectangular' height={56} width={'100%'}></Skeleton>
        ) : (
          <ControlDropdownInput
            control={control}
            error={errors.buildingUsageType}
            label={'add-emptying-location-building-info-use'}
            validations={['required']}
            dropdownItems={buildingUsageTypeOptions}
            name={'buildingUsageType'}
          />
        )}
        {buildingTypesLoading ? (
          <Skeleton variant='rectangular' height={56} width={'100%'}></Skeleton>
        ) : (
          <ControlDropdownInput
            control={control}
            error={errors.buildingType}
            label={'add-emptying-location-building-info-type'}
            validations={['required']}
            dropdownItems={buildingTypeOptions}
            name={'buildingType'}
          />
        )}
      </Stack>

      <ControlTextInput
        control={control}
        error={errors.population}
        label={'add-emptying-location-building-info-population'}
        validations={['required', 'positive-integer']}
        name={'population'}
      />

      <ControlTextInput
        control={control}
        error={errors.propertyId}
        label={'add-emptying-location-building-info-property-id'}
        validations={['positive-integer']}
        name={'propertyId'}
      />
    </Stack>
  );
};

export default BuildingInfoSection;
