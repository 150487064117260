import { useContext } from 'react';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { Control, FieldErrors } from 'react-hook-form';
import { LanguageContext } from '../../../../../contexts/language-context';
import ControlTextInput from '../../../../../components/common-materialui/form/ControlTextInput';
import { UiTexts } from '../../../../../model';
import LocationAccess from './LocationAccess';
import GrayWater from './need-for-emptying/GrayWater';
import BlackWater from './need-for-emptying/BlackWater';
import { BlackWaterForm, GrayWaterForm, LocationAccessForm, SepticTankForm } from '../models';
import { LargerThanBreakpoint } from '../../../../../util/viewportUtils';

export const defaultSepticTankValues = {
  numberOfLids: 0,
  volume: 0,
  noLimits: false,
  noWinterEmptyings: false,
  locked: false,
  weightLimit: false,
  expections: false,
  other: false,
  weightLimitDetails: '',
  otherDetails: '',
  location: '',
  grayWater: {
    weeklyFrequency: '',
    other: '',
  },
};

export const defaultSepticTankValuesOptionB = {
  numberOfLids: 0,
  volume: 0,
  noLimits: false,
  noWinterEmptyings: false,
  locked: false,
  weightLimit: false,
  expections: false,
  other: false,
  weightLimitDetails: '',
  otherDetails: '',
  location: '',
  blackWater: {
    weeklyFrequency: '',
    other: '',
    residentCount: false,
  },
};

interface Props {
  control: Control<SepticTankForm>;
  errors: FieldErrors<SepticTankForm>;
  formHeader: keyof UiTexts;
  grayWater: boolean;
  blackWater: boolean;
}

const SepticTank = ({ control, errors, formHeader, grayWater, blackWater }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const direction = LargerThanBreakpoint('md') ? 'row' : 'column';

  return (
    <Container>
      <Grid container direction={direction} wrap='nowrap' marginTop={2}>
        <Grid item xs={12} sm={7} md={8}>
          <Stack>
            <Typography variant='h6'>{getText(formHeader)}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Stack direction={direction} spacing={2}>
            <Box>
              <ControlTextInput
                control={control}
                inputType='number'
                name='numberOfLids'
                label='septic-tank-covers-number'
                error={errors.numberOfLids}
                validations={['required', 'positive-integer', 'integer-not-start-zero']}
              />
            </Box>
            <Box>
              <ControlTextInput
                control={control}
                inputType='number'
                name='volume'
                label='septic-tank-volume'
                error={errors.volume}
                validations={['required', 'gt_zero']}
              />
            </Box>
          </Stack>
          {grayWater && <GrayWater control={control as unknown as Control<GrayWaterForm>} errors={errors} />}
          {blackWater && <BlackWater control={control as unknown as Control<BlackWaterForm>} errors={errors} />}
          <LocationAccess control={control as unknown as Control<LocationAccessForm>} errors={errors} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SepticTank;
