import { useContext, useEffect, useState } from 'react';
import { Box, Button, Container, Divider, Stack, Typography } from '@mui/material';
import AuthContext from '../../../contexts/auth-context';
import { LanguageContext } from '../../../contexts/language-context';
import { LargerThanBreakpoint } from '../../../util/viewportUtils';
import { overlayDialogPadding } from '../../../components/common-materialui/OverlayDialog';
import ProgressComponent from '../../../components/common-materialui/ProgressComponent';
import ExtendedAlert from '../../../components/common-materialui/status-handling/ExtendedAlert';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { LocationInfoFormData } from './models';
import PrivateLocationContactSection from './add-location-form/PrivateLocationContactSection';
import BuildingInfoSection from './add-location-form/BuildingInfoSection';
import ContractInfoSection from './add-location-form/ContractInfoSection';
import { EmptyingLocationInfoInput } from '../../../service/authService';
import { useMutation } from '@tanstack/react-query';
import { CommonDetails, UiTexts } from '../../../model';
import BasicInfoSection from './add-location-form/BasicInfoSection';

interface Props {
  billingId: string;
  onPhaseChange: () => void;
  onCancel: () => void;
  customerType: 'private' | 'company';
  form: UseFormReturn<LocationInfoFormData, any>;
  commonDetails: CommonDetails;
}

const EmptyingLocationForm = ({
  billingId,
  onPhaseChange,
  onCancel,
  customerType,
  form,
  commonDetails,
}: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const [alertErrorMsg, setAlertErrorMsg] = useState<keyof UiTexts | null>(null);
  const { addEmptyingLocation } = useContext(AuthContext);

  const [currentBillingId, setCurrentBillingId] = useState<string>(billingId);

  const orientation = LargerThanBreakpoint('md') ? 'row' : 'column';
  const titleTypographyVariant = LargerThanBreakpoint('lg') ? 'h5' : 'h6';

  useEffect(() => {
    setCurrentBillingId(billingId);
  }, [billingId]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    resetField,
  } = form;

  const useSameEmptyingLocationBasicInfoPerson = useWatch({
    control,
    name: 'useSameEmptyingLocationBasicInfoPerson',
  });

  useEffect(() => {
    if (useSameEmptyingLocationBasicInfoPerson) {
      if (customerType === 'private') {
        setValue('firstName', commonDetails.firstName);
        setValue('lastName', commonDetails.lastName);
      } else {
        setValue('companyName', commonDetails.companyName);
      }
      setValue('address', commonDetails.address);
      setValue('postCode', commonDetails.postCode);
      setValue('city', commonDetails.city);
    } else {
      resetField('companyName');
      resetField('firstName');
      resetField('lastName');
      resetField('address');
      resetField('postCode');
      resetField('city');
    }
  }, [
    useSameEmptyingLocationBasicInfoPerson,
    setValue,
    resetField,
    customerType,
    commonDetails.address,
    commonDetails.postCode,
    commonDetails.city,
    commonDetails.firstName,
    commonDetails.lastName,
    commonDetails.companyName,
  ]);

  const useSameEmptyingLocationContactPerson = useWatch({
    control,
    name: 'useSameEmptyingLocationContactPerson',
  });

  useEffect(() => {
    if (useSameEmptyingLocationContactPerson) {
      setValue('contactFirstName', commonDetails.contactFirstName);
      setValue('contactLastName', commonDetails.contactLastName);
      setValue('contactPhone', commonDetails.phoneNumber);
      setValue('contactEmail', commonDetails.email);
    } else {
      resetField('contactFirstName');
      resetField('contactLastName');
      resetField('contactPhone');
      resetField('contactEmail');
    }
  }, [
    commonDetails.contactFirstName,
    commonDetails.contactLastName,
    commonDetails.phoneNumber,
    commonDetails.email,
    useSameEmptyingLocationContactPerson,
    setValue,
    resetField,
  ]);

  function convertAndSendAddLocationInfo(data: LocationInfoFormData) {
    // Mapping form data to endpoint body structure
    const inputData: EmptyingLocationInfoInput = {
      address: data.address,
      buildingClassificationCode: data.buildingType,
      operationCode: data.buildingUsageType,
      buildingId: data.permanentBuildingId,
      city: data.city,
      contactPersonFirstName: data.contactFirstName,
      contactPersonLastName: data.contactLastName,
      contractDate: data.startDate,
      email: data.contactEmail,
      firstName: customerType === 'private' ? data.firstName : undefined,
      lastName: customerType === 'private' ? data.lastName : undefined,
      companyName: customerType === 'company' ? data.companyName : undefined,
      municipalityCode: data.area,
      conurbation: data.conurbation,
      phoneNumber: data.contactPhone.replace(/\s/g, ''),
      postCode: data.postCode,
      realEstateId: data.propertyId,
      referenceId: data.referenceNumber,
      residentCount: data.population,
    };

    addNewEmptyingLocation(inputData);
  }

  const { mutate: addNewEmptyingLocation, isLoading: addNewEmptyingLocationIsLoading } = useMutation(
    (data: EmptyingLocationInfoInput) => {
      // Remove spaces from phone number
      const modifiedData = { ...data, phoneNumber: data.phoneNumber.replace(/\s/g, '') };
      return addEmptyingLocation(currentBillingId, modifiedData);
    },
    {
      onSuccess: () => onPhaseChange(),
      onError: () => setAlertErrorMsg('error-general-message'),
    }
  );

  return (
    <Stack direction='column'>
      <Container>
        <Stack p={overlayDialogPadding} direction={orientation} spacing={1} justifyContent={'space-between'}>
          <Typography variant={titleTypographyVariant}>{getText('add-emptying-location-basic-info')}</Typography>
          <BasicInfoSection
            control={control}
            errors={errors}
            customerType={customerType}
            disabled={useSameEmptyingLocationBasicInfoPerson}
          />
        </Stack>
      </Container>

      <Divider />

      <Container>
        <Stack p={overlayDialogPadding} direction={orientation} spacing={1} justifyContent={'space-between'}>
          <Typography variant={titleTypographyVariant}>{getText('add-emptying-location-contact')}</Typography>
          <PrivateLocationContactSection
            control={control}
            errors={errors}
            disabled={useSameEmptyingLocationContactPerson}
          />
        </Stack>
      </Container>

      <Divider />

      <Container>
        <Stack p={overlayDialogPadding} direction={orientation} spacing={1} justifyContent={'space-between'}>
          <Typography variant={titleTypographyVariant}>{getText('add-emptying-location-building-info')}</Typography>
          <BuildingInfoSection control={control} errors={errors} setAlertErrorMsg={setAlertErrorMsg} />
        </Stack>
      </Container>

      <Divider />

      <Container>
        <Stack p={overlayDialogPadding} direction={orientation} spacing={1} justifyContent={'space-between'}>
          <Typography variant={titleTypographyVariant}>{getText('add-emptying-location-contract-info')}</Typography>
          <ContractInfoSection control={control} errors={errors} />
        </Stack>
      </Container>

      <Divider />

      <ProgressComponent isLoading={addNewEmptyingLocationIsLoading} />
      <Container>
        <ExtendedAlert
          open={!!alertErrorMsg}
          onClose={() => setAlertErrorMsg(null)}
          severity='error'
          sx={{ marginBottom: 2 }}
        >
          {alertErrorMsg ? getText(alertErrorMsg) : null}
        </ExtendedAlert>

        <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
          <Box>
            <Button size='large' color='secondary' onClick={onCancel}>
              {getText('add-emptying-location-cancel')}
            </Button>
          </Box>
          <Box>
            <Button variant='contained' size='large' onClick={handleSubmit((x) => convertAndSendAddLocationInfo(x))}>
              {getText('add-emptying-location-save')}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
};

export default EmptyingLocationForm;
