import { useContext } from 'react';
import { Stack, Container, Box, Button, Divider } from '@mui/material';
import { LanguageContext } from '../../../../contexts/language-context';

interface Props {
  onPreviousPhaseChange: () => void;
}

const WasteWaterReportSummary = ({ onPreviousPhaseChange }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  return (
    <Stack direction={'column'}>
      <Stack height={80}></Stack>
      <Divider />
      <Container>
        <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
          <Box>
            <Button size='large' color='primary' onClick={onPreviousPhaseChange}>
              {getText('dialog-back')}
            </Button>
          </Box>
          <Box>
            <Button variant='contained' size='large'>
              {getText('dialog-save')}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
};

export default WasteWaterReportSummary;
