import { UiTexts } from '../../../../model';

export interface WellCollectionState {
  houseHoldWaterInfo: HouseHoldWaterInfo;
  wasteWater?: string[];
  septicTank?: SepticTankForm | {};
  septicTankOptionB?: SepticTankForm | {};
  other?: OtherForm | {};
  closedTank?: ClosedTankForm | {};
  treatmentPlant?: TreatmentPlantForm | {};
}

export interface HouseHoldWaterInfo {
  wcType: string;
  householdWaterSource: string;
  equipments: string[];
}

export interface HouseHoldWaterInfoFormState {
  wcType: string;
  householdWaterSource: string;
  equipments: Record<string, boolean>;
}

export enum WellCollectionEquipment {
  Shower = 'Shower',
  Dishwasher = 'Dishwasher',
  WashingMachine = 'WashingMachine',
}

export enum WellCollectionWCType {
  WaterWC = 'WaterWC',
  DryCompostFreezingOrBurning = 'DryCompostFreezingOrBurning',
}

export enum WellCollectionHouseholdWaterSource {
  PressureWater = 'PressureWater',
  CarriedWater = 'CarriedWater',
}

export enum WasteWaterOptionsEnum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export interface WasteWaterTreatmentOptionsForm {
  A: boolean;
  B: boolean;
  C: boolean;
  D: boolean;
  E: boolean;
  F: boolean;
  G: boolean;
}

export interface WasteWaterOptions {
  [key: string]: WasteWaterOptionsData;
}

export type WasteWaterOptionsData = {
  value: WasteWaterOptionsEnum;
  blackList: string[];
  label: keyof UiTexts;
};

export interface SepticTankForm extends LocationAccessForm {
  numberOfLids: number;
  volume: number;
  blackWater: BlackWater;
  grayWater: GrayWater;
}

export interface LocationAccessForm {
  noWinterEmptyings: boolean;
  noLimits: boolean;
  locked: boolean;
  weightLimit: boolean;
  weightLimitDetails: string;
  expections: boolean;
  other: boolean;
  otherDetails: string;
  location: string;
}

export enum OtherRadioGroupOptionsEnum {
  grayWaterFilter = 'grayWaterFilter',
  groundFilter = 'groundFilter',
  noWasteWaterTreatmentSystem = 'noWasteWaterTreatmentSystem',
}
export interface OtherForm {
  selection:
    | OtherRadioGroupOptionsEnum.grayWaterFilter
    | OtherRadioGroupOptionsEnum.groundFilter
    | OtherRadioGroupOptionsEnum.noWasteWaterTreatmentSystem;
  handlingMethod: string;
}
export interface TreatmentPlantForm extends LocationAccessForm {
  manufacturer: string;
  model: string;
  emptyingInstructions: string;
  volume: number;
  vacuumTruckEmptied: string;
  blackWater: BlackWater;
  grayWater: GrayWater;
}

export interface ClosedTankForm extends LocationAccessForm {
  volume: number;
  needForEmptying: GrayWater;
}

type GrayWater = {
  weeklyFrequency: string;
  other: string;
};

type BlackWater = GrayWater & {
  residentCount: boolean;
};

export interface GrayWaterForm {
  grayWater: GrayWater;
}

export interface BlackWaterForm {
  blackWater: BlackWater;
}

export enum WeeklyFrequencyEnum {
  Other = 'other',
  EveryHalfAYear = '26',
  EveryYear = '52',
  EverySecondYear = '104',
  EveryThirdyear = '156',
  OnDemand = 'onDemand',
}
